import React, { useState } from "react";

const UsersTable = ({ users }) => {
  const [visibleUsers, setVisibleUsers] = useState(20); // Start by showing 20 users

  // Helper function to deduplicate users
  const deduplicateUsers = (users) => {
    return Array.from(new Map(users.map(user => [user.id, user])).values());
  };

  // Handle "Show More" button click
  const handleShowMore = () => {
    setVisibleUsers((prev) => prev + 20);
  };

  // Helper function to format Firestore Timestamps
  const formatDate = (timestamp) => {
    if (!timestamp) return "N/A";
    const date = timestamp.toDate();
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  // Deduplicate and sort users by createdAt in descending order
  const sortedUsers = deduplicateUsers(users).sort((a, b) => {
    if (a.createdAt && b.createdAt) {
      return b.createdAt.toMillis() - a.createdAt.toMillis();
    }
    return a.createdAt ? -1 : 1;
  });

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <div className="overflow-x-auto">
        <table className="table-auto w-full border-collapse border border-gray-200 shadow-lg bg-white">
          <thead>
            <tr className="bg-gray-300 text-left">
              <th className="p-4 border border-gray-200">Created At</th>
              <th className="p-4 border border-gray-200">First Name</th>
              <th className="p-4 border border-gray-200">Last Name</th>
              <th className="p-4 border border-gray-200">Email</th>
              <th className="p-4 border border-gray-200">Mobile Number</th>
              <th className="p-4 border border-gray-200">Highest Education</th>
              <th className="p-4 border border-gray-200">Work Experience</th>
              <th className="p-4 border border-gray-200">Pin Code</th>
              <th className="p-4 border border-gray-200">Address</th>
            </tr>
          </thead>
          <tbody>
            {sortedUsers.slice(0, visibleUsers).map((user, index) => (
              <tr key={user.id || `user-${index}`} className={index % 2 === 0 ? "bg-gray-100" : "bg-white"}>
                <td className="p-4 border border-gray-200">
                  {user.createdAt ? formatDate(user.createdAt) : "N/A"}
                </td>
                <td className="p-4 border border-gray-200">{user.firstName || "N/A"}</td>
                <td className="p-4 border border-gray-200">{user.lastName || "N/A"}</td>
                <td className="p-4 border border-gray-200">{user.email || "N/A"}</td>
                <td className="p-4 border border-gray-200">{user.mobileNumber || "N/A"}</td>
                <td className="p-4 border border-gray-200">{user.highestEducation || "N/A"}</td>
                <td className="p-4 border border-gray-200">{user.workExperience || "N/A"}</td>
                <td className="p-4 border border-gray-200">{user.pinCode || "N/A"}</td>
                <td className="p-4 border border-gray-200">{user.address || "N/A"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Show More Button */}
      {visibleUsers < sortedUsers.length && (
        <div className="text-center mt-4">
          <button
            onClick={handleShowMore}
            className="bg-blue-600 text-white px-4 py-2 rounded shadow hover:bg-blue-700"
          >
            Show More
          </button>
        </div>
      )}
    </div>
  );
};

export default UsersTable;
