import React, { useState } from "react";
import { loginUser } from "../auth/AuthService";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const { user, role } = await loginUser(email, password);

      // Check if the email is the admin email
      if (email === "admin@ambeynexus.com") {
        toast.success("Welcome, Admin! check it now ", { position: "top-right" });
        navigate("/admin"); // Redirect to the admin dashboard
        return;
      }

      // Handle other roles
      if (role === "employer") {
        toast.success("Welcome back, Employer!", { position: "top-right" });
        navigate("/profile"); // Redirect to Employer Dashboard
      } else if (role === "job_seeker") {
        toast.success("Welcome back, Job Seeker!", { position: "top-right" });
        navigate("/work"); // Redirect to Job Seeker Dashboard
      } else {
        toast.error("Invalid role detected. Please contact support.", { position: "top-right" });
      }
    } catch (error) {
      toast.error("Error in login. Please check your credentials!", { position: "top-right" });
      // console.log(error);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-br from-blue-500 to-purple-600 p-4">
      <ToastContainer />
      <div className="w-full max-w-sm bg-white rounded-lg shadow-lg p-6 space-y-4">
        <h2 className="text-2xl font-bold text-center text-gray-700">Login</h2>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="input-field"
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="input-field"
        />
        <button
          onClick={handleLogin}
          className="btn-glow w-full bg-gradient-to-r from-purple-500 to-blue-500 text-white py-2 px-4 rounded-md font-semibold"
        >
          Login
        </button>
        <p className="text-sm text-center text-gray-500">
          Don't have an account? <Link to="/signup" className="text-blue-500 cursor-pointer">Sign up</Link>
        </p>
      </div>
    </div>
  );
};

export default Login;
