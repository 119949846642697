import React, { useState, useEffect } from "react";
import { db } from "../firebaseConfig"; // Firestore configuration
import { collection, getDocs } from "firebase/firestore";
import UsersTable from "./UserTable";

const AdminDashboard = () => {
  const [activeTab, setActiveTab] = useState("users"); // Tracks the active section
  const [users, setUsers] = useState([]);
  const [employers, setEmployers] = useState([]);
  const [jobPosts, setJobPosts] = useState([]);

  // Fetch data from Firestore
  useEffect(() => {
    const fetchData = async () => {
      try {
        const usersSnapshot = await getDocs(collection(db, "users"));
        const jobPostsSnapshot = await getDocs(collection(db, "jobPosts"));

        // Map user data and deduplicate by user.id
        const usersData = usersSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        const uniqueUsers = Array.from(
          new Map(usersData.map((user) => [user.id, user])).values()
        );

        const employersData = uniqueUsers.filter((user) => user.role === "employer");

        setUsers(uniqueUsers);
        setEmployers(employersData);
        setJobPosts(jobPostsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // Dynamic content rendering based on active tab
  const renderContent = () => {
    if (activeTab === "users") {
      return <UsersTable users={users} />;
    }

    if (activeTab === "employers") {
      return (
        <div className="grid grid-cols-1 gap-4">
          {employers.map((employer) => (
            <div key={employer.id} className="p-4 bg-white shadow rounded-lg">
              <p>
                <strong>Company Name:</strong> {employer.companyName || "N/A"}
              </p>
              <p>
                <strong>Contact Person:</strong> {employer.firstName} {employer.lastName}
              </p>
              <p>
                <strong>Email:</strong> {employer.email}
              </p>
              <p>
                <strong>Mobile Number:</strong> {employer.mobileNumber || "N/A"}
              </p>
            </div>
          ))}
        </div>
      );
    }

    if (activeTab === "jobPosts") {
      return (
        <div className="grid grid-cols-1 gap-4">
          {jobPosts.map((post) => (
            <div key={post.id} className="p-4 bg-pink-400 shadow rounded-lg">
              <p>
                <strong>Job Title:</strong> {post.title}
              </p>
              <p>
                <strong>Company Name:</strong> {post.companyName}
              </p>
              <p>
                <strong>Location:</strong> {post.location}
              </p>
            </div>
          ))}
        </div>
      );
    }
  };

  return (
    <div className="flex min-h-screen bg-gray-100">
      {/* Left Panel */}
      <aside className="w-1/4 bg-blue-600 text-white p-6 space-y-4">
        <button
          className={`w-full py-2 px-4 rounded ${
            activeTab === "users" ? "bg-pink-500" : ""
          }`}
          onClick={() => setActiveTab("users")}
        >
          All Users
        </button>
        <button
          className={`w-full py-2 px-4 rounded ${
            activeTab === "employers" ? "bg-yellow-500" : ""
          }`}
          onClick={() => setActiveTab("employers")}
        >
          Registered Employers
        </button>
        <button
          className={`w-full py-2 px-4 rounded ${
            activeTab === "jobPosts" ? "bg-red-500" : ""
          }`}
          onClick={() => setActiveTab("jobPosts")}
        >
          All Job Posts
        </button>
      </aside>

      {/* Right Panel */}
      <main className="flex-1 p-6">
        {/* Top Metrics */}
        <div className="grid grid-cols-3 gap-6 mb-6">
          <div className="p-4 bg-pink-500 shadow rounded-lg">
            <p className="text-lg font-bold">Total Users</p>
            <p className="text-2xl">{users.length}</p>
          </div>
          <div className="p-4 bg-yellow-400 shadow rounded-lg">
            <p className="text-lg font-bold">Total Employers</p>
            <p className="text-2xl">{employers.length}</p>
          </div>
          <div className="p-4 bg-red-500 shadow rounded-lg">
            <p className="text-lg font-bold">Total Job Posts</p>
            <p className="text-2xl">{jobPosts.length}</p>
          </div>
        </div>

        {/* Dynamic Content */}
        {renderContent()}
      </main>
    </div>
  );
};

export default AdminDashboard;
