import React, { useState } from "react";
import logo from "../../assets/dfehyjw5juj.png";
import { NavLink } from "react-router-dom";

const Navbar = ({ isLoggedIn, userRole }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleCloseMenu = () => {
    setIsOpen(false);
  };

  const activeClassName =
    "bg-orange-500 text-white rounded-lg px-3 py-1 transition";

  return (
    <nav className="bg-[hsl(238,72%,55%)] p-4 h-24">
      <div className="container mx-auto flex justify-between items-center">
        {/* Logo */}
        <div>
          <img
            src={logo}
            alt="My Logo"
            className="h-[60px] hover:cursor-pointer"
          />
        </div>

        {/* Hamburger icon for mobile */}
        <div className="md:hidden" onClick={() => setIsOpen(!isOpen)}>
          <button className="text-white focus:outline-none">
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d={
                  isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"
                }
              ></path>
            </svg>
          </button>
        </div>

        {/* Full Nav Links for larger screens */}
        <div className="hidden md:flex space-x-8 items-center font-bold text-xl mr-4">
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive ? activeClassName : "text-white hover:scale-105"
            }
          >
            Home
          </NavLink>
          <NavLink
            to="/about"
            className={({ isActive }) =>
              isActive ? activeClassName : "text-white hover:scale-105"
            }
          >
            About
          </NavLink>
          <NavLink
            to="/services"
            className={({ isActive }) =>
              isActive ? activeClassName : "text-white hover:scale-105"
            }
          >
            Services
          </NavLink>
          <NavLink
            to="/work"
            className={({ isActive }) =>
              isActive ? activeClassName : "text-white hover:scale-105"
            }
          >
            JOBS
          </NavLink>
          <NavLink
            to="/profile"
            className={({ isActive }) =>
              isActive ? activeClassName : "text-white hover:scale-105"
            }
          >
            Profile
          </NavLink>
          <NavLink
            to="/blogs"
            className={({ isActive }) =>
              isActive ? activeClassName : "text-white hover:scale-105"
            }
          >
            Blog
          </NavLink>
          <NavLink
            to="/training"
            className={({ isActive }) =>
              isActive ? activeClassName : "text-white hover:scale-105"
            }
          >
            Training Program
          </NavLink>
          <NavLink
            to="/contact"
            className={({ isActive }) =>
              isActive ? activeClassName : "text-white hover:scale-105"
            }
          >
            Contact
          </NavLink>
        </div>

        {/* Mobile Nav Links with slide-in effect */}
        <div
          className={`fixed top-0 right-0 h-full w-3/4 bg-[#3032dc] text-white transform transition-transform duration-300 ease-in-out z-50 ${
            isOpen ? "translate-x-0" : "translate-x-full"
          } md:hidden`}
        >
          <div className="flex flex-col space-y-6 p-8 text-xl font-extrabold">
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive ? activeClassName : "hover:scale-105"
              }
              onClick={handleCloseMenu}
            >
              Home
            </NavLink>
            <NavLink
              to="/about"
              className={({ isActive }) =>
                isActive ? activeClassName : "hover:scale-105"
              }
              onClick={handleCloseMenu}
            >
              About
            </NavLink>
            <NavLink
              to="/services"
              className={({ isActive }) =>
                isActive ? activeClassName : "hover:scale-105"
              }
              onClick={handleCloseMenu}
            >
              Services
            </NavLink>
            <NavLink
              to="/work"
              className={({ isActive }) =>
                isActive ? activeClassName : "hover:scale-105"
              }
              onClick={handleCloseMenu}
            >
              JOBS
            </NavLink>
            <NavLink
              to="/profile"
              className={({ isActive }) =>
                isActive ? activeClassName : "hover:scale-105"
              }
              onClick={handleCloseMenu}
            >
              Profile
            </NavLink>
            <NavLink
              to="/blogs"
              className={({ isActive }) =>
                isActive ? activeClassName : "hover:scale-105"
              }
              onClick={handleCloseMenu}
            >
              Blog
            </NavLink>
            <NavLink
              to="/training"
              className={({ isActive }) =>
                isActive ? activeClassName : "hover:scale-105"
              }
              onClick={handleCloseMenu}
            >
              Training Program
            </NavLink>
            <NavLink
              to="/contact"
              className={({ isActive }) =>
                isActive ? activeClassName : "hover:scale-105"
              }
              onClick={handleCloseMenu}
            >
              Contact
            </NavLink>
          </div>
        </div>

        {/* Overlay for closing menu when clicking outside */}
        {isOpen && (
          <div
            className="fixed inset-0 bg-black opacity-50 z-40"
            onClick={handleCloseMenu}
          ></div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
