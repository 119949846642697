import React, { useEffect, useState } from "react";
import { db } from "../firebaseConfig"; // Import Firebase Firestore instance
import { collection, getDocs, doc, getDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import { getAuth } from "firebase/auth";
import Marquee from "react-fast-marquee";

const JobPosts = () => {
  const [jobPosts, setJobPosts] = useState([]);
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const [waitingNumber, setWaitingNumber] = useState(null);
  const auth = getAuth();

  const MAX_LENGTH = 100;

  // Toggle description for a specific job post
  const toggleDescription = (jobId) => {
    setExpandedDescriptions((prevState) => ({
      ...prevState,
      [jobId]: !prevState[jobId],
    }));
  };
  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(userDocRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          if (data.role === "job_seeker") {
            setWaitingNumber(data.waitingNumber);
          }
        }
      }
    };

    fetchUserData();
  }, [auth.currentUser]);

  useEffect(() => {
    const fetchJobPosts = async () => {
      try {
        const jobRef = collection(db, "jobPosts");
        const snapshot = await getDocs(jobRef);
        const jobs = snapshot.docs.map((doc) => {
          const data = doc.data();
          const timestamp = data.timestamp ? data.timestamp.toDate() : null;
          const formattedDate = timestamp
            ? timestamp.toLocaleString("en-IN", {
                dateStyle: "medium",
                timeStyle: "short",
              })
            : "N/A";
          return { id: doc.id, ...data, formattedDate };
        });
        setJobPosts(jobs);
      } catch (error) {
        console.error("Error fetching job posts:", error);
      }
    };

    fetchJobPosts();
  }, []);

  return (
    <div className="max-w-7xl mx-auto p-6">
      <h2 className="text-3xl font-bold text-center text-indigo-600 mb-8">
        Find Your Dream Jobs
      </h2>
      {waitingNumber && (
        <Marquee
          gradient={false}
          speed={80}
          className="bg-red-300 py-2 px-4 border border-gray-300 rounded-md"
        >
          <span className="text-indigo-600 font-bold text-lg">
            &nbsp;&nbsp; Your Token Number: {waitingNumber} &nbsp;&nbsp; |
            &nbsp;&nbsp; 50 Slots Are avilabe &nbsp;&nbsp; | &nbsp;&nbsp;
            Connect with us on WhatsApp for the latest updates on job openings
            and interview schedules. Simply save the number and send 'Hi' to
            7889274462. ||
          </span>
        </Marquee>
      )}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5">
        {jobPosts.map((job) => {
          const isExpanded = expandedDescriptions[job.id] || false;
          return (
            <div
              key={job.id}
              className="p-6 bg-white shadow-lg rounded-lg border border-gray-200 hover:shadow-2xl transition-shadow duration-300"
            >
              <h3 className="text-xl font-semibold text-indigo-700 mb-2">
                {job.title}
              </h3>
              <p className="text-gray-600 mb-2">
                <strong>Job Description: </strong>
                {isExpanded || job.description.length <= MAX_LENGTH
                  ? job.description
                  : `${job.description.slice(0, MAX_LENGTH)}...`}
                {job.description.length > MAX_LENGTH && (
                  <span
                    onClick={() => toggleDescription(job.id)}
                    className="text-blue-500 cursor-pointer ml-2"
                  >
                    {isExpanded ? "Read Less" : "Read More"}
                  </span>
                )}
              </p>
              <p className="text-gray-600 mb-1">
                <span className="font-semibold">
                  <strong>Location:</strong>
                </span>{" "}
                {job.location}
              </p>
              <p className="text-gray-600 mb-1">
                <span className="font-semibold">
                  <strong>Salary:</strong>
                </span>{" "}
                {job.salary}
              </p>
              <p className="text-gray-600 mb-1">
                <span className="font-semibold">
                  <strong>Company Name:</strong>
                </span>{" "}
                {job.companyName}
              </p>
              <p className="text-gray-600 mb-4">
                <span className="font-semibold">
                  <strong>Openings:</strong>
                </span>{" "}
                {job.openings}
              </p>
              <p className="text-gray-600 mb-4">
                <span className="font-semibold">
                  <strong>Posted On:</strong>
                </span>{" "}
                {job.formattedDate}
              </p>
              <button
                className="w-full bg-gradient-to-r from-red-400 to-orange-500 text-white py-2 px-4 rounded-md font-medium shadow-md transform transition duration-300 hover:scale-105 focus:outline-none "
                onClick={() =>
                  toast.success(
                    `Congratulations! You Have Successfully Applied for ${job.title}. Connect with us on WhatsApp for the latest updates on job openings and interview schedules. Simply save the number and send 'Hi' to 7889274462`
                  )
                }
                // navigate()
              >
                Apply Now
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default JobPosts;
