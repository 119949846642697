import React, { useState, useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai"; // For the close icon
import { FaWhatsappSquare } from "react-icons/fa";

const WhatsAppPopup = () => {
  const [showPopup, setShowPopup] = useState(false);

  // Show the popup after 5 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPopup(true);
    }, 3000);

    return () => clearTimeout(timer); // Cleanup the timer
  }, []);

  // Function to close the popup
  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    showPopup && (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div className="bg-gradient-to-r from-purple-500 to-indigo-500 w-80 h-80 rounded-lg shadow-xl flex flex-col items-center justify-between p-6 relative text-white">
          <FaWhatsappSquare size={50} className="text-green-400" />
          <p className="text-center font-semibold text-lg">
            For quick updates on <strong>job openings</strong> and <strong>interview schedules</strong>, connect with us on WhatsApp!
          </p>
          <p className="bg-white text-green-600 font-bold px-4 py-2 rounded-full shadow-md hover:bg-green-100 transition">
            +91 7889274462
          </p>
          <button
            onClick={handleClosePopup}
            className="absolute top-3 right-3 text-white hover:text-red-500"
          >
            <AiOutlineClose size={20} />
          </button>
        </div>
      </div>
    )
  );
};

export default WhatsAppPopup;
