import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { ToastContainer } from "react-toastify";
import ProtectedRoute from "./ProtectedRoute";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";
import HomePage from "./components/home/HomePage";
import AboutUs from "./components/about/AboutUs";
import Contact from "./components/contact/Contact";
import Seo from "./components/services/seo/Seo";
import WevDev from "./components/services/development/WevDev";
import Gd from "./components/services/graphicsDesigning/Gd";
import Services from "./components/services/Servises";
import Training from "./components/services/training/Training";
import ComputerBasics from "./components/services/computerBasics/ComputerBasics";
import SpokenEnglishCoursePage from "./components/services/training/SpokenEnglishCoursePage";
import PDPCoursePage from "./components/services/training/PDPCoursePage ";
import PrivacyPolicy from "./components/privacy/PrivacyPolicy";
import FAQPage from "./components/faq/FaqPage";
import ScrollToTop from "./components/utility/ScrollTop";
import Signup from "./components/auth/Signup";
import Login from "./components/auth/Login";
import Work from "./components/work/Work";
import Profile from "./components/profile/Profile";

import LoadingSpinner from "./components/LoadingSpinner";
import JobPostForm from "./components/work/JobPostForm";
import Blogs from "./components/blog/Blogs";
import BlogDetailPage from "./components/blog/BlogDetailPage";
import BlogPost from "./components/blog/BlogData";
import WhatsAppPopup from "./components/popUp/WhatsappPopUp";
import Marquee from "react-fast-marquee";
import AdminDashboard from "./components/admin/AdminDashboard";


const App = () => {
  const [user, setUser] = useState(null); // Track logged-in user
  const [loading, setLoading] = useState(true); // Track loading state

  // Handle authentication state
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser); // Update user state
      setLoading(false); // Stop loading
    });

    return () => unsubscribe(); // Cleanup listener
  }, []);

  if (loading) {
    // Show a loader while checking auth state
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <LoadingSpinner className="w-16 h-16 text-blue-500 animate-spin" />
      </div>
    );
  }

  return (
    <Router>
      <div>
        <Navbar />
        <ScrollToTop />
        <Marquee
          gradient={false}
          speed={80}
          className="bg-red-300 py-2 px-4 border border-gray-300 rounded-md"
        >
          <span className="text-indigo-600 font-bold text-lg">
          &nbsp;&nbsp; Ambey Nexus never charges any fees of any kind for job opportunities &nbsp;&nbsp; | &nbsp;&nbsp;
            Connect with us on WhatsApp for the latest updates on job openings
            and interview schedules. Save the number and send 'Hi' to
            7889274462. ||
          </span>
        </Marquee>
        <main className="p-4">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/services" element={<Services />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/training" element={<Training />} />
            <Route path="/courses/web-development" element={<WevDev />} />
            <Route path="/training/social-media-marketing" element={<Seo />} />
            <Route path="/courses/graphic-design" element={<Gd />} />
            <Route
              path="courses/computer-basics"
              element={<ComputerBasics />}
            />
            <Route
              path="courses/spoken-english-courses"
              element={<SpokenEnglishCoursePage />}
            />
            <Route
              path="courses/personality-development-program"
              element={<PDPCoursePage />}
            />
            <Route path="/contact" element={<Contact />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/faq" element={<FAQPage />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/login" element={<Login />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/blog/:id" element={<BlogDetailPage />} />

            <Route
              path="/work"
              element={
                <ProtectedRoute user={user}>
                  <Work />
                </ProtectedRoute>
              }
            />
            <Route
              path="/profile"
              element={
                <ProtectedRoute user={user}>
                  <Profile />
                </ProtectedRoute>
              }
            />

            <Route
              path="/create-job-post"
              element={
                <ProtectedRoute user={user}>
                  <JobPostForm />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin"
              element={
                <ProtectedRoute user={user}>
                  <AdminDashboard />
                </ProtectedRoute>
              }
            />
          </Routes>
          <ToastContainer />
          <WhatsAppPopup />
        </main>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
