import React from "react";
import "./Landingpage.css";
import { Link } from "react-router-dom";
import aboutImg from "../../assets/about.jpg";
const LandingPage = () => {
  return (
    <div className="landing-page">
      {/* Background Section */}
      <div className="landing-background">
        <div className="overlay"></div>
        <div className="landing-content">
          <h1 className="font-extrabold ">
            Welcome To{" "}
            <span className=" text-blue-500 f-[2rem]">Ambey Nexus</span>
          </h1>
          <span className=" text-xl font-bold">An ISO 9001:2015 Certified</span>
          <p className=" m-2 p-2">
          Welcome to Ambey Nexus, your one-stop destination for a wide range of essential services. We provide reliable job consultation services to help you find the right career opportunities. Trust us to make your tasks simpler and guide you in achieving your goals efficiently! Our expertise extends to Digital Marketing and Banking services, along with customized Digital solutions designed to meet your needs. Boost your skills with our Computer Training programs and take advantage of our seamless online services for PAN card applications, Passport processing, and Government job assistance.
          </p>
          <a href="#services" className="cta-button">
            Explore Our Services
          </a>
        </div>
      </div>

      {/* Services Section */}
      <section id="services" className="py-16 bg-gray-10">
        <h1 className=" text-4xl font-extrabold text-center mb-12 text-blue-600">
          Our Services
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 px-4 md:px-12 lg:px-24">
          {/* Service Cards */}
          <div className="bg-white shadow-lg rounded-lg p-6 hover:shadow-2xl transition duration-300">
            <h3 className=" font-bold mb-4 text-orange-500 font-crimson text-3xl">
              Job Consultation service
            </h3>
            <p className="mb-4 font-crimson text-[1.2rem] font-semibold">
            At Ambey Nexus, we provide expert Job Consultation Services to help you find the right career opportunities. Whether you're starting your career or seeking new challenges, we offer personalized guidance, including career counseling, resume building, job search assistance, and interview preparation. Our goal is to match your strengths with the best job opportunities to boost your chances of success. Best of all, Ambey Nexus never charges any fees from candidates. Sign up now for job opportunities, and let us guide you toward a fulfilling career, simplifying the job search process and supporting your career growth.
            </p>
            <Link
              to="/work"
              className="text-orange-500 hover:text-orange-600 font-semibold"
            >
              Read More
            </Link>
          </div>
          <div className="bg-white shadow-lg rounded-lg p-6 hover:shadow-2xl transition duration-300">
            <h3 className=" font-bold mb-4 text-orange-500 font-crimson text-3xl">
              Digital Marketing
            </h3>
            <p className="mb-4 font-crimson text-[1.2rem] font-semibold">
              Our digital marketing services are designed to boost your online
              presence and drive engagement. We offer tailored strategies
              including social media management, search engine optimization
              (SEO), and content marketing to help your business reach its
              target audience effectively. Let us elevate your brand's
              visibility and enhance customer connections in the digital
              landscape.
            </p>
            <Link
              to="/services"
              className="text-orange-500 hover:text-orange-600 font-semibold"
            >
              Read More
            </Link>
          </div>
          <div className="bg-white shadow-lg rounded-lg p-6 hover:shadow-2xl transition duration-300">
            <h3 className="font-bold mb-4 text-orange-500 text-3xl font-crimson">
              Online Digital Services
            </h3>
            <p className="text-gray-700 mb-4 font-crimson text-[1.2rem] font-semibold">
              Our online digital services streamline essential tasks, making
              your life easier and more efficient. From document copying and
              form filling to applying for jobs and updating Aadhaar cards, we
              handle it all. We also assist with PAN card applications, typing
              work, and school projects, ensuring you have reliable support for
              your everyday needs.
            </p>
            <Link
              to="/services"
              className="text-orange-500 hover:text-orange-600 font-semibold"
            >
              Read More
            </Link>
          </div>
          <div className="bg-white shadow-lg rounded-lg p-6 hover:shadow-2xl transition duration-300">
            <h3 className="font-bold mb-4 text-orange-500 text-[1.7rem] font-crimson">
              Trainings and Certifications
            </h3>
            <p className="text-gray-700 mb-4 font-crimson text-[1.2rem] font-semibold">
              Ambey Nexus offer comprehensive online and offline training in
              Social Media Marketing, Graphic Designing, Web Development, and
              Computer Courses ranging from basic to advanced levels. Our
              expert-led programs are designed to equip learners with practical
              skills and industry knowledge, making them job-ready.
            </p>
            <Link
              to="/training"
              className="text-orange-500 hover:text-orange-600 font-semibold"
            >
              Read More
            </Link>
          </div>
          <div className="bg-white shadow-lg rounded-lg p-6 hover:shadow-2xl transition duration-300">
            <h3 className="font-bold mb-4 text-orange-500 text-3xl font-crimson">
              All Types Of Typing work
            </h3>
            <p className="text-gray-700 mb-4 font-crimson text-[1.2rem] font-semibold">
              Hindi, English, Punjabi.
            </p>
            <Link
              to="/services"
              className="text-orange-500 hover:text-orange-600 font-semibold"
            >
              Read More
            </Link>
          </div>

          <div className="bg-white shadow-lg rounded-lg p-6 hover:shadow-2xl transition duration-300">
            <h3 className="font-bold mb-4 text-orange-500 text-3xl font-crimson">
              Numerology And Vastu
            </h3>
            <p className="text-gray-700 mb-4 font-crimson text-[1.2rem] font-semibold">
              Coming Soon
            </p>
            <Link
              to="/services"
              className="text-orange-500 hover:text-orange-600 font-semibold"
            >
              Read More
            </Link>
          </div>
        </div>
      </section>

      {/* About Us Section */}
      <section
        id="about-us"
        className="py-16 bg-slate-300 flex flex-col md:flex-row items-center justify-center px-4 md:px-12 lg:px-24"
      >
        {/* Left: Content */}
        <div className="md:w-1/2 mb-8 md:mb-0">
          <h2 className="text-4xl font-bold mb-6 font-crimson">About Us</h2>
          <p className="text-gray-700 text-lg mb-6 font-crimson text-[1.2rem]">
            We are a team of experts with over 7 years of experience in Digital
            Marketing to help businesses grow online.
          </p>
          <p className="text-gray-700 text-lg m-4 font-crimson text-[1.2rem]">
            A Welcome to Ambey Nexus, your trusted hub for a wide array of essential services designed to empower you and meet your diverse needs. Our mission is to provide expert digital marketing solutions that help businesses grow their online presence and reach their target audience effectively. We also specialize in comprehensive banking services, ensuring that your financial management is seamless and efficient. At Ambey Nexus, we understand the importance of skill enhancement in today’s competitive landscape. That's why we offer a range of computer training courses tailored to equip you with the latest knowledge and expertise in technology. Additionally, our digital solutions simplify various processes, allowing you to apply for PAN cards, process passports, and access government job assistance with ease. With a commitment to excellence and customer satisfaction, we strive to simplify your tasks and help you achieve your goals efficiently. TFrust Ambey Nexus to be your partner in navigating the complexities of modern life, as we work together to unlock new opportunities and foster growth
          </p>
          <button className="bg-purple-600 text-white font-semibold px-6 py-3 rounded-lg hover:bg-purple-700 hover:shadow-lg transition duration-300">
            <Link to="/about" className="text-white hover:scale-105">
              {" "}
              Read More..
            </Link>
          </button>
        </div>

        {/* Right: Image */}
        <div className="md:w-1/2">
          <img
            src={aboutImg}
            alt="About Us"
            className=" rounded-br-[12rem] object-cover w-[30rem] h-[30rem] text-center m-auto"
          />
        </div>
      </section>
    </div>
  );
};

export default LandingPage;
