import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { auth, db } from "../firebaseConfig";
import { signInWithEmailAndPassword } from "firebase/auth";
import { toast } from "react-toastify";

// Signup function
export const signupUser = async (formData) => {
    try {
      const { email, password, ...userDetails } = formData;
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
  
      // Save additional user details in Firestore
      await setDoc(doc(db, "users", user.uid), {
        ...userDetails,
        email: user.email,
        createdAt: new Date(),
      });
  
      toast.success("registration Successfull")
      return user;
    } catch (error) {
     toast.error(error.message, {position:'top-right'})
    }
  };


// Login function
export const loginUser = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    // Fetch role from Firestore
    const userDoc = await getDoc(doc(db, "users", user.uid));
    if (userDoc.exists()) {
      return { user, role: userDoc.data().role };
    } else {
      throw new Error("No such user!");
    }
  } catch (error) {
    throw error;
  }
};
